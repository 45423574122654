.info {
    padding: 32px 0px;
    width: 100%;
}
.image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    font-size: 40px;
    line-height: 48px;
}
.text {
    padding: 32px !important;
}
.paragraph {
    color: #60718a;
    font-size: 18px;
    line-height: 28px;
}
@media (max-width: 991px) {
    .text h1 {
        text-align: center;
    }
}
