.contact-card {
    padding: 48px;
    margin-bottom: 64px;
    position: relative;
    @media (max-width: 991px) {
        & {
            padding: 16px;
        }
    }
}
.contact-divider {
    text-align: center;
    > div {
        height: 100%;
    }
    @media (max-width: 991px) {
        & {
            display: none;
        }
    }
}
h4.contact-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
}
h3.contact-number {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
}
.contact-form {
    padding-top: 32px;
}
.contact-agreement {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    &-highlight {
        color: #47a07e;
    }
}
.contact-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: flex;
        align-items: center;
        margin-top: 28px;
    }
    &-icon {
        background: #74c11d;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-right: 16px;
        flex-shrink: 0;
    }
    a {
        color: #74c11d;
    }
}

.contact-bg-dots {
    color: #47a07e;
    position: absolute;
    bottom: -70px;
    right: 64px;
    opacity: 0.7;
    transform: rotate(90deg);
}
