.hero-card {
    background: #eff7f4;
    padding: 48px 0;
}
.hero-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;

    &::first-letter {
        color: #47a07e;
    }
}
.hero-paragraph {
    font-size: 18px;
    line-height: 28px;

    padding: 16px 0;
}
.hero-btn {
    font-weight: 600;
    border-width: 2px;
    padding: 8px 28px;
}
