.card {
    background: #319d72;
    background: linear-gradient(278.22deg, #319d72 0%, #a1c776 98.74%);
    color: #fff;
    padding: 48px 32px;
    position: relative;
    margin-top: 44px;
    margin-bottom: 80px;
}
.text {
    color: #beeebe !important;
    font-weight: 700 !important;
    margin: 0 !important;
}
.texts {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
.partner-btn {
    color: #319d72;
    margin-top: 16px;
    padding: 8px 28px;
    font-weight: 600;
}
.list {
    list-style: none;
    padding: 0;
    li {
        font-weight: 600;
        line-height: 24px;
        margin: 24px 0;
        opacity: 0;
    }
    li::before {
        content: '•';
        color: #fcbb07;
        font-size: 28px;
        margin-right: 16px;
    }
    &:global(.fade-in) li :global {
        animation: fadeIn ease 2s;
        animation-fill-mode: forwards;
        animation-delay: 0.9s;
    }
    li:nth-child(2) {
        animation-delay: 1.4s;
    }
    li:nth-child(3) {
        animation-delay: 1.8s;
    }
}
.bg-dots {
    position: absolute;
    top: -55px;
    left: 20%;
    opacity: 0.4;
}
.bg-element {
    position: absolute;
    bottom: -14px;
    right: 15%;
    opacity: 0.2;
}

@media (max-width: 991px) {
    .card {
        padding: 8px;
    }
}
