.layout {
    min-width: 470px;
}
.content {
    margin-top: 72px;
    padding: 16px;
}
.text-content {
    padding: 64px 16px 32px 16px !important;
    text-align: center;

    > h2 {
        font-size: 32px;
        line-height: 42px;
    }

    > div {
        color: #60718a;
        font-size: 18px;
        line-height: 28px;
    }
}
