.product-card {
    background: linear-gradient(
        180deg,
        #fafcec -1.32%,
        rgba(255, 255, 255, 0) 63.43%
    );
    padding: 32px 16px;
    overflow: hidden;
    position: relative;

    :global(div.ant-typography) {
        color: #60718a;
    }
}
h2.product-title {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    margin: 0 0 32px 0;
}
h4.product-subtitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 32px 0 !important;
}
.product-text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 28px !important;
}
.product-fg {
    position: relative;
    z-index: 0;
}
.product-bg-object {
    background: #74c11d;
    position: absolute;
    z-index: 0;
    height: 500px;
    width: 800px;
    border-radius: 120px;
    top: -100px;
    right: -50px;
    opacity: 0.1;
    transform: rotate(-45deg);
}
.product-image {
    display: flex;
}
.product-laptop {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}
@media (min-width: 991px) {
    .product-laptop {
        max-height: 400px;
        min-width: 679px;
    }
}
