.hero-graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > img {
        border: 16px solid #eff7f4;
        box-shadow: 0px 0px 0px 2px #c2e3d5;
        border-radius: 50%;
        max-width: 100%;
    }
}
.hero-graphic-label {
    position: absolute;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 8px;
    box-shadow: -2px 4px 45px rgba(0, 0, 0, 0.13);
    animation: float 4s linear infinite;

    &:nth-child(2) {
        animation-delay: 0.15s;
        animation-direction: alternate-reverse;
    }
    &:nth-child(3) {
        animation-delay: 0.3s;
        animation-direction: reverse;
    }
    &:nth-child(4) {
        animation-delay: 0.45s;
        animation-direction: alternate;
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 48px;
        width: 48px;
        flex-shrink: 0;
        margin-right: 8px;
    }
}

@keyframes float {
    from {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    to {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}
